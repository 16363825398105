import axios, { AxiosRequestConfig } from 'axios'
import { Auth } from 'aws-amplify'
import app_config from '../config'

axios.defaults.baseURL = app_config.API_ENDPOINT
/*

// Send a POST request
axios({
  method: 'post',
  url: '/user/12345',
  data: {
    firstName: 'Fred',
    lastName: 'Flintstone'
  }
})

*/

const request = async (config: AxiosRequestConfig, withAuth = true) => {
  let headers = {}

  if (withAuth) {
    const session = await Auth.currentSession()

    const idTokenExpire = session.getIdToken().getExpiration()
    const refreshToken = session.getRefreshToken()
    const currentTimeSeconds = Math.round(Date.now() / 1000)

    if (idTokenExpire < currentTimeSeconds) {
      const user = await Auth.currentAuthenticatedUser()
      user.refreshSession(refreshToken, (err, data) => {
        if (err) {
          Auth.signOut()
        } else {
          const jwt = data.getAccessToken().getJwtToken()
          headers = {
            Authorization: jwt,
          }
        }
      })
    } else {
      const jwt = session.getAccessToken().getJwtToken()
      headers = {
        Authorization: jwt,
      }
    }
  }

  const response = await axios({
    ...config,
    headers,
  })

  return response
}

export { request }
