import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Flex, Text, Link, Grid, Divider } from '@chakra-ui/react'

const footerLinks = [
  {
    title: 'Documentation',
    href: '/documentation',
  },
  // {
  //   title: 'Blog',
  //   href: '/blog'
  // },
  {
    title: 'Contact',
    href: '/contact',
  },
  {
    title: 'Terms of Service',
    href: '/terms-of-service',
  },
  {
    title: 'Privacy Policy',
    href: '/privacy-policy',
  },
]

const Footer = () => {
  return (
    <Flex
      as="footer"
      bg="gray.700"
      bottom="0"
      color="gray.100"
      height="300px"
      justifyContent="center"
      position="absolute"
      py={8}
      width="100%"
    >
      <Flex flexDirection="column" maxWidth="3xl">
        <Grid gridGap={8} gridTemplateColumns="1fr 1fr">
          <Flex flexDirection="column">
            <Text fontWeight="bold" mb="4">
              About Snapiant
            </Text>
            <Text fontSize="sm">
              We offer a clean and straight forward API that anyone can use to
              simplify their screenshot needs. We use cloud technology that
              enables us to scale with ease, stability, and cost efficiency.
            </Text>
          </Flex>

          <Flex alignItems="flex-end" flexDirection="column">
            <Text fontWeight="bold" mb="4">
              Links
            </Text>

            {footerLinks.map(link => {
              return (
                <Link
                  key={link.title}
                  as={GatsbyLink}
                  fontSize="sm"
                  mb="3"
                  to={link.href}
                >
                  {link.title}
                </Link>
              )
            })}
          </Flex>
        </Grid>

        <Divider my="5" />

        <Text color="gray.400" fontSize="sm" textAlign="center">
          © {new Date().getFullYear()}
          {` `}
          Snapiant - All rights reserved
        </Text>
      </Flex>
    </Flex>
  )
}

export { Footer }
