import React, { useMemo, useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { navigate } from '@reach/router'
import { useMachine } from '@xstate/react'
import { userMachine, events } from './user.machine'

export type User = {
  email: string
  name: string
  remaining: number
  limit: number
  subscription: {
    id: number
    current_period_end: number
    current_period_start: number
  }
}

type UserContext = {
  onLogout: () => {}
  isAuthenticated: boolean
  user: User
  isLoading: boolean
  isUpdating: boolean
  send: () => {}
  success: boolean
  isInitialized: boolean
}

const UserContext = React.createContext<UserContext>(undefined)

function UserProvider(props) {
  const [isInitialized, setIsInitialized] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [currentState, send] = useMachine(userMachine)

  const onLoad = async () => {
    try {
      await Auth.currentSession()

      setIsAuthenticated(true)
      send(events.GET_USER)
      setIsInitialized(true)
    } catch (error) {
      console.log({ ONLOAD: error })

      setIsAuthenticated(false)
      setIsInitialized(true)
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  const onLogout = async () => {
    await Auth.signOut()
    setIsAuthenticated(false)
    send(events.CLEAR_USER)
    navigate('/login')
  }

  const { user } = currentState.context

  const isLoading = currentState.matches('getUser') || !user

  const isUpdating = currentState.matches('updateUser')

  const success = currentState.matches('success')

  const value = useMemo(
    () => ({
      isAuthenticated,
      isLoading,
      isUpdating,
      onLogout,
      send,
      success,
      user,
      isInitialized,
    }),
    [isAuthenticated, isLoading, user, isUpdating, send, success, isInitialized]
  )

  return <UserContext.Provider value={value} {...props} />
}

function useUserService() {
  const userCtx = React.useContext(UserContext)

  if (!userCtx) {
    throw new Error(`useUserService must be used within an UserProvider`)
  }

  return { ...userCtx }
}

const userEvents = events

export { UserProvider, useUserService, userEvents }
