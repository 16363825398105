import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
  Box,
  Flex,
  Text,
  Link,
  IconButton,
  Button,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaMoon, FaSun, FaArrowRight } from 'react-icons/fa'
import { useUserService } from '../features/user/user.service'

const Navbar = () => {
  const { isAuthenticated, onLogout } = useUserService()
  const { toggleColorMode, colorMode } = useColorMode()

  const SwitchIcon = useColorModeValue(FaSun, FaMoon)
  const text = useColorModeValue('light', 'dark')
  const bgColor = { light: 'white', dark: 'gray.800' }
  const textColor = { light: 'gray.800', dark: 'gray.400' }

  return (
    <Box
      as="header"
      bg={bgColor[colorMode]}
      borderBottom="1px solid"
      borderBottomColor="gray.100"
      color={textColor[colorMode]}
      left="0"
      position="fixed"
      right="0"
      top="0"
      zIndex="sticky"
    >
      <Flex justifyContent="center" my="3">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          maxWidth="6xl"
          px={5}
          width="100%"
        >
          <Text as={GatsbyLink} fontSize="2xl" fontWeight="bolder" to="/">
            Snapiant
          </Text>
          <Flex ml={10}>
            <Link
              aria-label="documentation"
              as={GatsbyLink}
              fontWeight="medium"
              px={4}
              to="/documentation"
            >
              Documentation
            </Link>
            {/* <Link
            px={4}
            as={GatsbyLink}
            aria-label="blog"
            to="/blog"
            fontWeight='medium'
          >
            Blog
          </Link> */}
          </Flex>
          <Flex alignItems="center" flex="1" justifyContent="flex-end">
            <IconButton
              aria-label={`Switch to ${text} mode`}
              color="current"
              fontSize="lg"
              icon={<SwitchIcon />}
              mr={4}
              onClick={toggleColorMode}
              size="md"
              variant="ghost"
            />

            {isAuthenticated ? (
              <>
                <Link
                  aria-label="user dashboard"
                  as={GatsbyLink}
                  px={4}
                  to="/dashboard"
                >
                  Dashboard
                </Link>
                <Button data-testid="logout" ml={2} onClick={onLogout}>
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Link aria-label="login" as={GatsbyLink} ml={2} to="/login">
                  <Button variant="outline">Log in</Button>
                </Link>
                <Link
                  aria-label="signup"
                  as={GatsbyLink}
                  ml={2}
                  px={4}
                  to="/signup"
                >
                  <Button rightIcon={<FaArrowRight />} colorScheme='cyan'>
                    Sign up free
                  </Button>
                </Link>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box >
  )
}

export { Navbar }
