import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Amplify } from 'aws-amplify'
import { Helmet } from 'react-helmet'
import { Navbar } from './navbar.tsx'
import { Footer } from './footer.tsx'
import config from '../config/index.ts'
import { UserProvider } from '../features/user/user.service.tsx'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
})

const Layout = ({ children }) => {
  return (
    <React.StrictMode>
      <Helmet>
        <script src="https://js.stripe.com/v3/" />
      </Helmet>
      <Box minHeight="100vh" position="relative">
        <UserProvider>
          <Navbar />
          <Flex
            alignItems="center"
            as="main"
            flexDirection="column"
            maxWidth="6xl"
            mt="24"
            mx="auto"
            pb="380px"
            px={5}
          >
            {children}
          </Flex>
          <Footer />
        </UserProvider>
      </Box>
    </React.StrictMode>
  )
}

export default Layout
