import { Machine, assign } from 'xstate'
import { request } from '../../utils/request'

const events = {
  GET_USER: 'getUser',
  UPDATE_USER: 'updateUser',
  CLEAR_USER: 'clearUser',
  SUBMIT: 'submit',
  CHANGE_SUBSCRIPTION: 'changeSubscription',
  CACHE_USER: 'cacheUser',
}

// SERVICES

const getUser = () => {
  return request({
    method: 'GET',
    url: '/v1/user',
  })
}

const updateUser = (ctx: any, evt: any) => {
  return request({
    method: 'POST',
    url: evt.url,
    data: evt.payload,
  })
}

// OPTIONS

const authOpts = {
  services: {
    getUser,
    updateUser,
  },
  actions: {
    cacheError: assign((ctx, evt: any) => {
      console.log({ error: evt })

      return {
        error: evt.data?.message,
      }
    }),
    cacheUser: assign((ctx, evt) => {
      if (ctx.user) {
        return {
          user: {
            ...ctx.user,
            ...evt.payload,
          },
        }
      }

      return {
        user: evt.payload,
      }
    }),
    removeError: assign({ error: null }),
    clearUser: assign({ user: null }),
    saveUser: assign((ctx, evt: any) => {
      if (ctx.user) {
        return {
          user: {
            ...ctx.user,
            ...evt.data.data,
          },
        }
      }

      return {
        user: evt.data.data,
      }
    }),
  },
  guards: {},
}

const userMachine = Machine<any>(
  {
    id: 'user-machine',
    initial: 'idle',
    context: {
      user: null,
      error: null,
    },
    states: {
      idle: {
        on: {
          [events.GET_USER]: 'getUser',
          [events.UPDATE_USER]: 'updateUser',
          [events.CHANGE_SUBSCRIPTION]: 'updateUser',
          [events.CACHE_USER]: 'cacheUser',
          [events.CLEAR_USER]: 'clearUser',
        },
      },
      clearUser: {
        entry: 'clearUser',
        after: {
          500: {
            target: 'idle',
          },
        },
      },
      getUser: {
        invoke: {
          id: 'get-user',
          src: 'getUser',
          onDone: {
            target: 'idle',
            actions: 'saveUser',
          },
          onError: {
            target: 'error',
            actions: ['cacheError'],
          },
        },
      },
      cacheUser: {
        entry: 'cacheUser',
        on: {
          '': 'idle',
        },
      },
      updateUser: {
        invoke: {
          id: 'update-user',
          src: updateUser,
          onDone: {
            target: 'success',
            actions: 'saveUser',
          },
          onError: {
            target: 'error',
            actions: ['cacheError'],
          },
        },
      },
      success: {
        after: {
          500: {
            target: 'idle',
          },
        },
      },
      error: {
        on: {
          [events.SUBMIT]: 'idle',
        },
        exit: 'removeError',
      },
    },
  },
  authOpts
)

export { userMachine, events }
