export default {
  cognito: {
    REGION: process.env.GATSBY_COGNITO_REGION,
    USER_POOL_ID: process.env.GATSBY_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.GATSBY_COGNITO_APP_CLIENT_ID,
  },
  API_ENDPOINT: process.env.GATSBY_API_ENDPOINT,
  SCREENSHOT_ENDPOINT: process.env.GATSBY_SCREENSHOT_ENDPOINT,
  STRIPE_KEY: process.env.GATSBY_STRIPE_KEY,
  GA_KEY: process.env.GATSBY_GA_KEY,
  isDev: process.env.NODE_ENV === 'development',
}
